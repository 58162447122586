import images from './images';

const wines = [
  {
    title: 'ADU addition fast track',
    price: '2 months total | 4 months end to end',
    tags: 'with preapproved plan | customized plan',
  },
  {
    title: 'Framing',
    price: '1-2 month',
    tags: 'with inpsection | permit sign off',
  },
  {
    title: 'Foundation and pouring',
    price: '1-2 month',
    tags: 'with inpsection | permit sign off',
  },
  {
    title: 'Subfloor Engineering',
    price: '1 month',
    tags: 'with inpsection | permit sign off',
  },
  {
    title: 'Insulation',
    price: '1-2 weeks',
    tags: 'with inpsection | permit sign off',
  },

];

const cocktails = [
  {
    title: 'Stucco',
    price: '2-3 months',
    tags: 'with inpsection | permit sign off',
  },
  {
    title: "Roofing",
    price: '3-4 weeks',
    tags: 'with inpsection | permit sign off',
  },
  {
    title: 'Windows & exterior doors',
    price: '1 week',
    tags: 'with inpsection | permit sign off',
  },
  {
    title: 'Main water',
    price: '2-3 weeks',
    tags: 'with inpsection | permit sign off',
  },
  {
    title: 'Grading and Hardscape',
    price: '1 month',
    tags: 'with inpsection | permit sign off',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Palo Alto New Construction',
    subtitle: 'Green building tier 2, title 24.',
  },
  {
    imgUrl: images.award01,
    title: 'Palo Alto New Construction ADU',
    subtitle: 'Green building tier 2, Green Halo, title 24.',
  },
  {
    imgUrl: images.award05,
    title: 'AAA Finishing quality inside out',
    subtitle: 'level 5 finishing of interior wall.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Builder',
    subtitle: '4 layers color stucco smmoth finishing.',
  },
];

export default { wines, cocktails, awards };
