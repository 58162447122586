import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        <p className="p__opensans">2460 Park Blvd, Palo Alto, CA 94306, CA, USA</p>
        <p className="p__opensans">+1 408-345-5508 leave message or SMS</p>
        <p className="p__opensans">svden3@gmail.com</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <a href="https://calendly.com/jnjx88/15min?month=2022-01/" className="p__opensans">Book a Zoom meeting</a>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.SvDen} alt="footer_logo" />
        <p className="p__opensans">&quot;Let the best of professional to help you to realize your dream. put your wealth at work.&quot;</p>
        <img src={images.spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">07:00 am - 6:00 pm</p>
        <p className="p__opensans">Saturday</p>
        <p className="p__opensans">08:00 am - 6:00 pm</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2021 SvDen. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;
