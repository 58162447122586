import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Builder.css';

const Builder = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.builder} alt="builder_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Builder's word" />
      <h1 className="headtext__cormorant">What we believe in</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote_image" />
          <p className="p__opensans">We are hearted supporter of saving schools, revitalize local communities and acommondate more young families!</p>
        </div>
        <p className="p__opensans"> ADU and SB9 is opening a chance for a win win, home owners can realize their gains and we save the neighborhood with new families and new investment. </p>
      </div>

      <div className="app__chef-sign">
        <p>Jim Xiao: California State Licensed # 1049430</p>
        <p className="p__opensans">Builder & Founder</p>
        <img src={images.sign} alt="sign_image" />
      </div>
    </div>
  </div>
);

export default Builder;
