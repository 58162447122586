import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="SB9 new law started Jan. 1, 2022 + ADU: could be a win win for Home owners and neighborhoods" />
      <h1 className="app__header-h1">A golden chance to improve and reinvest your home!</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>SB9 and ADU new law is now official and finalized.
        Invest and harvest to convert your property into a cash income stream! </p>
      
        <p className="p__opensans" style={{ margin: '2rem 0' }}>either long term lease to work professionals w/o kids attending to schools with the extra school attendency privileges or AirBnb for high income. </p>
      
        <p className="p__opensans" style={{ margin: '2rem 0' }}>The same time may keep your property tax base and with the income above to quality you for a mortgage.</p>
      <button type="button" className="custom__button">Explore Options</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
);

export default Header;
