import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.Adu} alt="A_overlay" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">CLSB licensed builder. Also a high tech startup guy passionated with helping the movements of saving schools and affordable housing. To revitalize the aging neighborhood.
        
        </p>
        <p className="p__opensans">I support SB9 and ADU but not to covert the premium neighborhood to a concrete forrest but to maintain the style and taste and all heritages.
        
        </p>

        <p className="p__opensans">Other important factors I am always bearing in mind is : innovation, better life to help to acommondate young generations for a vivid healthy neighborhood!
        
        </p>

        <button type="button" className="custom__button">Know More</button>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Got into Real estate business since 2007, worked with Palo Alto, Los Altos Hill, Saratoga, Cupertino, Atherton and San Jose</p>
        <p className="p__opensans">Started to build and learnt to build since 2014;</p>
        <p className="p__opensans">Licensed in 2019 and now transitioned into total online high tech style builder!</p>
        <button type="button" className="custom__button">Know More</button>
      </div>
    </div>
  </div>
);

export default AboutUs;
